<template>
  <div>
    <div v-if="showHeader" class="row">
      <div class="col-12 col-sm">
        <h3>Личные данные</h3>
      </div>
      <div class="col-auto mb-3 mb-sm-0 col-md-12">
        <div class="checkbox">
          <input id="non-resident" v-model="noResident" type="checkbox"/>
          <label for="non-resident" class="checkbox" >Нерезидент РФ</label>
        </div>
      </div>
    </div>
    <base-input label="Фамилия" name="lastname" v-model="user.lastname"></base-input>
    <base-input label="Имя" name="firstname" v-model="user.firstname"></base-input>
    <base-input :validate="false" label="Отчество" name="middlename" v-model="user.middlename"></base-input>
    <div class="form-item form-item_groupped form-item_groupped_required">
      <label for=""><span>Пол</span></label>
      <div class="checkboxes">
        <div class="row">
          <div class="col-auto">
            <div class="radio">
              <input v-model="user.sex" type="radio" id="male" name="gender" value="male"/>
              <label class="radio" for="male">Мужской</label>
            </div>
          </div>
          <div class="col-auto">
            <div class="radio">
              <input v-model="user.sex" type="radio" id="female" name="gender" value="female"/>
              <label class="radio" for="female">Женский</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-item form-item_groupped form-item_groupped_required">
      <label for="region"><span>Регион{{ noResident ? ' пребывания' : '' }}</span></label>
      <autocomplete :class="{invalid: errors.has('region_name_ru')}" path="regions?filters[country]=1&filters[regionNameRu]=" id="region" name="region_name_ru" v-model="user.region" placeholder="Регион" />
    </div>
    <template v-if="showAddress">
      <base-input :label="'Почтовый адрес' + (noResident ? ' пребывания' : '')" :validate="!eventMode ? 'required' : false" name="address" v-model="user.address"></base-input>
      <base-input label="Квартира" :validate="!memberMode && !eventMode ? 'required' : false" name="flat" v-model="user.flat"></base-input>
    </template>
    <base-input name="phone" v-model="user.phone" :validate="'required|min:18'" v-if="showPhone" :mask="{mask: '+7 (000) 000-00-00', lazy: true}" label="Телефон" />
    <base-input name="snils" v-if="memberMode || showSnils" v-model="user.snils" :validate="'required|min:14'" :mask="{mask: '000-000-000 00', lazy: true}" label="СНИЛС" />
  </div>
</template>

<script>

import Autocomplete from "@/components/Autocomplete.vue";
import BaseInput from "@/components/form/BaseInput.vue";
export default {
  name: "UserSettings",
  components: {BaseInput, Autocomplete},
  inject: ['$validator'],
  props: {
    value: {
      type: Object,
      required: true
    },
    showAddress: {
      type: Boolean,
      default: true
    },
    eventMode: {
      type: Boolean,
      default: false
    },
    memberMode: {
      type: Boolean,
      default: false
    },
    showSnils: {
      type: Boolean,
      default: false
    },
    showPhone: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      noResident: false,
      user: {
        ...this.value,
        lastname: this.value.lastname ? this.value.lastname : '' ,
        firstname: this.value.firstname ? this.value.firstname : '',
        middlename: this.value.middlename ? this.value.middlename : '',
        sex: this.value.sex ? this.value.sex : 'male',
        region: this.value.region ? this.value.region : {} ,
        address: this.value.address ? this.value.address : '',
        flat: this.value.flat ? this.value.flat : ''
      }
    }
  },
  watch: {
    user: {
      handler: function(val) {
        this.$emit('input', val)
      },
      deep: true,
      immediate: true
    },
    'user.snils': {
      handler: function (val) {
        if(val.length > 14) {
          this.user.snils = val.slice(0, -1)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>