<template>
  <div class="modal fade" id="recallme" tabindex="-1" role="dialog" aria-labelledby="add-question" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <form @submit.prevent="submit">
        <div class="modal-content">
          <div class="modal-header">
            <div class="title-with-icon">
              <div class="title-with-icon__icon title-with-icon__icon_phone"></div>
              <div class="title-with-icon__title">Заказать звонок</div>
            </div>
            <button id="modal-close" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="!wasSend" class="form-item">
              <label for="phone">Пожалуйста, укажите Ваш номер телефона</label>
              <input v-model="phone" class="form-control" :class="{'is-invalid': isError}" id="phone" required placeholder="+7 (999) 999 9999" />
              <div class="invalid-feedback">
                Проверьте правильность ввода номера
              </div>
            </div>
            <div v-else class="form-item">
              <label>Спасибо! Ваш запрос отправлен.</label>
            </div>
          </div>
          <div v-if="!wasSend" class="modal-footer">
            <button type="submit" class="link-target"><span>Заказать</span></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
window.jQuery = $
import ApiService from "../../services/api.service";
export default {
  name: "ModalRecallMe",
  data() {
    return {
      phone: null,
      isError: false,
      wasSend: false
    }
  },
  mounted() {
    document.getElementById('phone').addEventListener('input', function (e) {
      e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      if(e.target.value[0] == 8) {
        e.target.value = ''
      }
    });
  },
  methods: {
    submit() {
      if(!this.phone || this.phone.length < 18) {
        this.isError = true
        return;
      }
      this.isError = false
      ApiService.post('recallme', {
        phone: this.phone
      }).then(() => {
        this.phone = null
        $('#modal-close').trigger('click');
        this.wasSend = true
      }).catch(() => {
        this.isError = true
      })
    }
  }
}
</script>

<style scoped>

</style>